<template>
  <div class="logo">
    <svg :width="width" :height="height" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.1" d="M5.71071 53.7303C2.1886 50.6339 2.18861 45.6139 5.71071 42.5175V42.5175C9.23282 39.4212 14.9433 39.4212 18.4654 42.5175L46.1005 66.8118C49.6226 69.9081 49.6226 74.9282 46.1005 78.0245V78.0245C42.5784 81.1208 36.868 81.1208 33.3458 78.0245L5.71071 53.7303Z" fill="#00C2FF" />
      <path d="M19.5283 64.0085C16.5932 61.4283 16.5932 57.2448 19.5283 54.6646V54.6646C22.4634 52.0843 27.2221 52.0843 30.1572 54.6646L45.0377 67.7461C47.9728 70.3263 47.9728 74.5098 45.0377 77.09V77.09C42.1026 79.6703 37.3439 79.6703 34.4088 77.09L19.5283 64.0085Z" fill="#00FF99" />
      <path opacity="0.1" d="M33.3458 14.4857C29.8237 11.3894 29.8237 6.36934 33.3458 3.27304V3.27304C36.868 0.176735 42.5784 0.176736 46.1005 3.27304L73.7356 27.5672C77.2578 30.6635 77.2578 35.6836 73.7356 38.78V38.78C70.2135 41.8763 64.5031 41.8763 60.981 38.78L33.3458 14.4857Z" fill="#00C2FF" />
      <path d="M34.4087 13.5514C31.4736 10.9711 31.4736 6.78768 34.4087 4.20743V4.20743C37.3438 1.62718 42.1025 1.62718 45.0376 4.20743L59.918 17.2889C62.8531 19.8692 62.8531 24.0526 59.918 26.6329V26.6329C56.9829 29.2131 52.2242 29.2131 49.2891 26.6329L34.4087 13.5514Z" fill="#438DA4" />
      <path d="M18.4653 33.1737C13.1821 28.5292 13.1821 20.9991 18.4653 16.3546V16.3546C20.2264 14.8065 23.0816 14.8065 24.8426 16.3546L60.9809 48.124C66.264 52.7684 66.264 60.2986 60.9809 64.943V64.943C59.2198 66.4912 56.3646 66.4912 54.6035 64.943L18.4653 33.1737Z" fill="#00C2FF" />
    </svg>

  </div>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
    color: String,
  },
  data() {
    return {
      // fill: "#ddd",
      // color: "#03D0BF",
      // width: "101",
      // height: "55",
    };
  },
};
</script>
<style lang="sass" scoped>
.logo

  /* border: 1px solid grey; */
</style>

